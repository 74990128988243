import { useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBackIosNewOutlined as Back } from "@mui/icons-material";

import { Text } from "../../shared/uiComponents";
import { useScreenWidth } from "../../shared/Helpers/hooks";

interface StateProperties {
  previousPage: string;
  currentPage: string;
}

const initialState: StateProperties = {
  previousPage: "",
  currentPage: "",
};

enum ACTIONS {
  setPreviousPage = "setPreviousPage",
  setCurrentPage = "setCurrentPage",
}
interface ActionProperties {
  type: keyof typeof ACTIONS;
  payload: any;
}

const PathReducer = (
  state: StateProperties,
  action: ActionProperties
): StateProperties => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.setCurrentPage:
      return {
        ...state,
        currentPage: payload,
      };

    case ACTIONS.setPreviousPage:
      return {
        ...state,
        previousPage: payload,
      };
    default: {
      return state;
    }
  }
};

const Previous = () => {
  const [{ currentPage, previousPage }, setData] = useReducer(
    PathReducer,
    initialState
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const screenWidth = useScreenWidth();

  useEffect(() => {
    if (!currentPage) {
      setData({ type: ACTIONS.setCurrentPage, payload: pathname });
      return;
    }
    if (pathname === currentPage) return;
    if (currentPage.includes("new")) return;
    setData({ type: ACTIONS.setPreviousPage, payload: currentPage });
    setData({ type: ACTIONS.setCurrentPage, payload: pathname });
  }, [pathname, currentPage]);

  return screenWidth > 768 ? (
    <Text
      title="Previous"
      className="breadcrampLink"
      size="smallBold"
      onClick={() => navigate(previousPage)}
    />
  ) : (
    <Back onClick={() => navigate(previousPage)} />
  );
};

export default Previous;
