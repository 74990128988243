import { FetchAPI } from "./Main/fetchAPI";
import { IDENTITY } from "./constants";
import {
    AddRoleProperties,
    AssignRoleProperties,
    ChangeEmailProperties,
    CompleteChangeEmailProperties,
    CompleteChangePassword,
    ConfirmEmailProperties,
    LoginProperties,
} from "./identityAPIProperties";

export const IdentityAPI = {
    Account: {
        confirmEmail: (data: ConfirmEmailProperties) => FetchAPI.postJson({
            urlStr: `${IDENTITY}/account/confirm-email`,
            data
        }),
        forgetPassword: (email: string) => FetchAPI.postJson({
            urlStr: `${IDENTITY}/account/forgot-password?email=${encodeURIComponent(email)}`
        }),
        login: (data: LoginProperties) => FetchAPI.postJson({
            urlStr: `${IDENTITY}/account/login`,
            data
        }),
        refresh: (refreshToken: string) => FetchAPI.postJson({
            urlStr: `${IDENTITY}/account/refresh?refreshToken=${refreshToken}`
        }),
        recoverPassword: (data: CompleteChangePassword) => FetchAPI.postJson({
            urlStr: `${IDENTITY}/account/complete-change-password`, data
        }),
        getRoles: () => FetchAPI.postJson({
            urlStr: `${IDENTITY}/account/roles`,
        }),
        confirmEmailChange: (data: CompleteChangeEmailProperties) => FetchAPI.postJson({
            urlStr: `${IDENTITY}/account/complete-change-email`,
            data,
        }),
    },
    Claim: {
        getClaims: () => FetchAPI.getJson({
            urlStr: `${IDENTITY}/claims`,
        }),
    },
    Role: {
        getRoles: () => FetchAPI.getJson({
            urlStr: `${IDENTITY}/roles`,
        }),
        getRole: (id: string) => FetchAPI.getJson({
            urlStr: `${IDENTITY}/roles/${id}`,
        }),
        addRole: (data: AddRoleProperties) => FetchAPI.postJson({
            urlStr: `${IDENTITY}/roles`,
            data
        }),
        updateRole: (data: AddRoleProperties) => FetchAPI.putJson({
            urlStr: `${IDENTITY}/roles/${data.id}`,
            data
        }),
        deleteRole: (id: string) => FetchAPI.deleteJson({
            urlStr: `${IDENTITY}/roles/${id}`,
        }),
        getSections: () => FetchAPI.getJson({
            urlStr: `${IDENTITY}/roles/sections`,
        }),
    },
    User: {
        assignRole: (data: AssignRoleProperties) => FetchAPI.postJson({
            urlStr: `${IDENTITY}/users/${data.userId}/assign-role`,
            data,
        }),
        getUserRoles: (userId: string) => FetchAPI.postJson({
            urlStr: `${IDENTITY}/users/${userId}/roles`,
        }),
        getUserAccountDetails: (userId: string) => FetchAPI.getJson({
            urlStr: `${IDENTITY}/users/${userId}`,
        }),
        resendVerificationEmail: (userId: string) => FetchAPI.postJson({
            urlStr: `${IDENTITY}/users/${userId}/resend-verification-email`,
        }),
        changeEmail: (data: ChangeEmailProperties) => FetchAPI.postJson({
            urlStr: `${IDENTITY}/users/${data.userId}/start-change-email`,
            data,
        }),
    }
}