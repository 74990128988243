import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

import { EventDataProperties } from "../../../../redux/API/ClientAPIHelpers/calendarProperties";
import { DispatchProperties, useSelector } from "../../../../redux/store";
import { DayProperties } from "../Helpers/interfaces";
import { useDispatch } from "react-redux";
import {
  getEvents,
  setInfoEvent,
} from "../../../../redux/State/clientSlice/calendarSlice";
import { clearTargets } from "../../../../redux/State/clientSlice/sessionSlice";
import { useSearchParams } from "react-router-dom";

export const useCheckAvailableSession = ({
  startTimeValue,
  endTimeValue,
  selectedDay,
}: {
  startTimeValue: Dayjs | null;
  endTimeValue: Dayjs | null;
  selectedDay: DayProperties;
}) => {
  const { day, month, year } = selectedDay;

  const allEvents = useSelector((state) => state.calendar.events);

  const [events, setEvents] = useState<Array<EventDataProperties>>([]);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (!allEvents) return;
    const selectedDayEvents = allEvents.filter(
      (x) =>
        new Date(x.startTime).getDate() === day &&
        new Date(x.startTime).getMonth() === month &&
        new Date(x.startTime).getFullYear() === year
    );
    setEvents(selectedDayEvents);
  }, [allEvents, day, month, year]);

  useEffect(() => {
    if (!events || !events.length || !startTimeValue || !endTimeValue) return;
    const hour = startTimeValue.hour();
    const minute = startTimeValue.minute();
    const startTime = new Date(year, month, day, hour, minute);
    const endHour = endTimeValue.hour();
    const endMinute = endTimeValue.minute();
    const endTime = new Date(year, month, day, endHour, endMinute);
    const sessions = events.filter(
      (event) =>
        (dayjs(event.startTime).isAfter(dayjs(startTime)) &&
          dayjs(event.startTime).isBefore(dayjs(endTime))) ||
        (dayjs(event.endTime).isAfter(dayjs(startTime)) &&
          dayjs(event.endTime).isBefore(dayjs(endTime)))
    );
    if (!!sessions.length) {
      setError("You have a session.");
      return;
    }
    setError("");
  }, [events, startTimeValue, day, month, year, endTimeValue]);

  return error;
};

export const useResetCalendarData = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const days = useSelector((state) => state.calendar.displayedCalendarPageDays);

  const resetData = () => {
    dispatch(setInfoEvent(false));
    dispatch(clearTargets());
    // dispatch(clearSessionInfo());
    
    const userId = params.get("userId");
    const clientId = params.get("clientId");
    const includeRbtEvents =
      params.get("includeRbtEvents") === "true" ? true : false;
    dispatch(getEvents({ days, clientId, userId, includeRbtEvents }));
  };

  return { resetData };
};
