import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { ChangeEvent, useState } from "react";
import { Dayjs } from "dayjs";
import { SelectChangeEvent } from "@mui/material";

import {
  CustomInput,
  CustomSelect,
} from "../../../../../../shared/uiComponents";
import {
  validateDecimalNumber,
  validateNumber,
} from "../../../../../../shared/Helpers/functions";
import { EditAuthorizationProperties } from "./editAuthorizationContent";
import Delete from "../../../../../../components/Delete";
import { useCalculateHoursPerWeek } from "../hook";

import { useSelector } from "../../../../../../redux/store";

const AuthorizationDetail = ({
  item,
  startDateValue,
  endDateValue,
  update,
  remove,
  authorizationTypeId,
}: {
  item: EditAuthorizationProperties;
  startDateValue: Dayjs;
  endDateValue: Dayjs;
  update: (item: EditAuthorizationProperties) => void;
  remove: (item: EditAuthorizationProperties) => void;
  authorizationTypeId: number;
}) => {
  const [typeId, setTypeId] = useState<string>(item.authorizationSessionTypeId);
  const [frequency, setFrequency] = useState<number>(item.frequency);
  const [totalHours, setTotalHours] = useState<string>(`${item.totalHours}`);
  const [customHoursPerWeek, setCustomHoursPerWeek] = useState<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const { hoursPerWeek, loading } = useCalculateHoursPerWeek({
    startDateValue,
    endDateValue,
    totalHours,
    frequency,
  });

  const sessionTypes = useSelector(
    (state) => state.authorization.insuranceAuthorizationSessionTypes
  );

  const onChangeSessionType = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setTypeId(value);
    const frequencyId = sessionTypes.find((x) => x.id === value)?.frequencies[0]
      .id;
    if (!frequencyId) return;
    setFrequency(frequencyId);
    setTotalHours("0"); 
  };

  const frequencyHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    if (!validateNumber(value)) return;
    setFrequency(parseInt(value));
  };

  const onTotalHoursChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!validateDecimalNumber(value.replace(/^0+/, ""))) return;
    setTotalHours(value.replace(/^0+/, ""));
    setCustomHoursPerWeek("");
  };

  const onHoursPerWeekChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!validateDecimalNumber(value.replace(/^0+/, ""))) return;
    if (!value.length) {
      setCustomHoursPerWeek("0");
      return;
    }
    setCustomHoursPerWeek(value.replace(/^0+/, ""));
  };

  const addDetailHandler = () => {
    const data: EditAuthorizationProperties = {
      id: item.id,
      authorizationSessionTypeId: typeId,
      frequency,
      totalHours: parseFloat(totalHours),
      hoursPerWeek:
        frequency === 1
          ? !!customHoursPerWeek
            ? parseFloat(customHoursPerWeek)
            : item.totalHours === parseFloat(totalHours)
            ? item.hoursPerWeek
            : hoursPerWeek
          : null,
    };
    update(data);
    setEdit(false);
  };

  const removeAuthorizationDetailHandler = () => remove(item);

  const cancelEditAuthorizationHandler = () => {
    setEdit(false);
    setTypeId(item.authorizationSessionTypeId);
    setFrequency(item.frequency);
    setTotalHours(`${item.totalHours}`);
    setCustomHoursPerWeek("");
  };

  return (
    <div
      style={{
        border: "1px solid black",
        borderRadius: "12px",
        position: "relative",
      }}
      className="padding8 marginBottom8"
    >
      <div style={{ display: "flex", gap: "16px" }} className="marginBottom8">
        <div style={{ minWidth: "350px" }}>
          <CustomSelect
            label="Choose session type:"
            data={sessionTypes}
            value={typeId}
            setValue={onChangeSessionType}
            className="marginBottom16"
            disabled={!edit}
          />
        </div>
        <CustomSelect
          label="Frequency"
          data={
            sessionTypes
              .find((x) => x.id === typeId)
              ?.frequencies.map((x) => ({
                id: `${x.id}`,
                label: x.name,
              })) as Array<any>
          }
          value={`${frequency}`}
          disabled={!edit}
          setValue={frequencyHandler}
          className="width150"
        />
      </div>
      <div style={{ display: "flex", gap: "16px" }} className="marginBottom8">
        <CustomInput
          label={`Total hours`}
          disabled={!edit}
          value={totalHours}
          setValue={onTotalHoursChange}
        />
        {frequency === 1 && (
          <CustomInput
            label={`Weekly limit`}
            disabled={!edit}
            value={
              !!customHoursPerWeek
                ? parseFloat(customHoursPerWeek)
                : item.totalHours === parseFloat(totalHours)
                ? item.hoursPerWeek
                : hoursPerWeek
            }
            setValue={onHoursPerWeekChange}
          />
        )}
        {!loading && (
          <div className="marginTop24" style={{ display: "flex", gap: "8px" }}>
            {edit ? (
              !!parseFloat(totalHours) && (
                <>
                  <CheckOutlined fontSize="small" onClick={addDetailHandler} />
                  <CloseOutlined
                    fontSize="small"
                    onClick={cancelEditAuthorizationHandler}
                  />
                </>
              )
            ) : (
              <EditOutlined fontSize="small" onClick={() => setEdit(true)} />
            )}
            {authorizationTypeId === 2 && (
              <Delete deleteHandler={removeAuthorizationDetailHandler} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthorizationDetail;
