import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    CompleteChangeEmailProperties,
    CompleteChangePassword,
    ConfirmEmailProperties,
    ErrorProperties,
    ExtendedRoleProperties,
    LoginProperties,
    LoginResponseProperties,
    RoleProperties,
    UserProperties,
} from "../../API/identityAPIProperties";
import API from "../../API/Main/API";
import { resetState } from "../../resetState";

interface AccountStateProperties {
    loading: boolean;
    loadingRole: boolean;
    loadingRefreshToken: boolean;
    user: UserProperties;
    role: ExtendedRoleProperties | null;
    roles: Array<RoleProperties> | null;
    error: ErrorProperties;
    roleRestriction: number;
}

const initialAccountState: AccountStateProperties = {
    loading: false,
    loadingRole: false,
    loadingRefreshToken: false,
    error: {
        status: 0,
        title: "",
    },
    role: null,
    roles: null,
    user: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
    },
    roleRestriction: 0,
}

export const login = createAsyncThunk(
    '/account/login',
    async (data: LoginProperties, thunkAPI) => {
        const response = await API.IdentityAPI.Account.login(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as LoginResponseProperties;
    }
)

export const forgetPassword = createAsyncThunk(
    '/account/password/forget',
    async (email: string, thunkAPI) => {
        const response = await API.IdentityAPI.Account.forgetPassword(email);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const recoverAccountPassword = createAsyncThunk(
    '/account/password/recover',
    async (data: CompleteChangePassword, thunkAPI) => {
        const response = await API.IdentityAPI.Account.recoverPassword(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const confirmAccount = createAsyncThunk(
    '/account/confirm',
    async (data: ConfirmEmailProperties, thunkAPI) => {
        const response = await API.IdentityAPI.Account.confirmEmail(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const refresh = createAsyncThunk(
    '/account/refresh',
    async (refreshToken: string, thunkAPI) => {
        const response = await API.IdentityAPI.Account.refresh(refreshToken);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as LoginResponseProperties;
    }
)

export const getAccountRoles = createAsyncThunk(
    '/account/invite',
    async (_, thunkAPI) => {
        const response = await API.IdentityAPI.Account.getRoles();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<ExtendedRoleProperties>;
    }
)

export const getRoleConfigruation = createAsyncThunk(
    '/calendar/role/configuration',
    async (_, thunkAPI) => {
        const response = await API.ClientAPI.RoleConfiguration.getConfiguration();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as { maxDaysForSessionCreation: number };
    }
)

export const completeEmailChange = createAsyncThunk(
    '/account/email/change/complete',
    async (data: CompleteChangeEmailProperties, thunkAPI) => {
        const response = await API.IdentityAPI.Account.confirmEmailChange(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

const accountSlice = createSlice({
    name: 'account',
    initialState: initialAccountState,
    reducers: {},
    extraReducers: (builder) => {
        //getRoleConfigruation
        builder.addCase(getRoleConfigruation.pending, (state) => {
            state.loadingRole = true;
        })
        builder.addCase(getRoleConfigruation.fulfilled, (state, action) => {
            state.loadingRole = false;
            state.roleRestriction = action.payload.maxDaysForSessionCreation + 1;
        })
        builder.addCase(getRoleConfigruation.rejected, (state, action) => {
            state.loadingRole = false;
            state.error = action.error as ErrorProperties;
        })
        //login
        builder.addCase(login.pending, (state) => {
            state.loading = true;
            state.error = initialAccountState.error;
        })
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload.user;
            state.role = action.payload.role;
        })
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //refresh
        builder.addCase(refresh.pending, (state) => {
            state.loadingRefreshToken = true;
            state.error = initialAccountState.error;
        })
        builder.addCase(refresh.fulfilled, (state, action) => {
            state.loadingRefreshToken = false;
            if (state.user.id === action.payload.user.id) return;
            state.user = action.payload.user;
            state.role = action.payload.role;
        })
        builder.addCase(refresh.rejected, (state, action) => {
            state.loadingRefreshToken = false;
            state.error = action.payload as ErrorProperties;
        })
        //getAccountRoles
        builder.addCase(getAccountRoles.pending, (state) => {
            state.loading = true;
            state.error = initialAccountState.error;
        })
        builder.addCase(getAccountRoles.fulfilled, (state, action) => {
            state.loading = false;
            if (!!action.payload && !!action.payload.length) {
                state.role = action.payload[0];
            }
            state.roles = action.payload;
        })
        builder.addCase(getAccountRoles.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //reset state
        builder.addCase(resetState, () => initialAccountState);
    }
})

export default accountSlice.reducer;
