import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../API/Main/API";
import { resetState } from "../../resetState";
import {
    AssignRoleProperties,
    ChangeEmailProperties,
    ErrorProperties,
    ExtendedRoleProperties,
    RoleSectionProperties,
    UserRegistrationInfoProperties,
} from "../../API/identityAPIProperties";

interface UserStateProperties {
    loading: boolean;
    loadingAccountInfo: boolean;
    error: ErrorProperties;
    roles: Array<ExtendedRoleProperties> | null;
    role: ExtendedRoleProperties;
    sections: Array<RoleSectionProperties> | null;
    accountInfo: UserRegistrationInfoProperties;
}

const initialUserState: UserStateProperties = {
    loading: false,
    loadingAccountInfo: false,
    error: {
        status: 0,
        title: '',
    },
    roles: null,
    role: {
        id: "",
        name: "",
        claims: [],
        description: "",
        createdAt: "",
        modifiedAt: "",
        isReadonly: false,
        createdBy: {
            id: "",
            firstName: "",
            lastName: "",
            email: "",
        },
        numberOfUsage: 0,
        section: { id: 0, name: '' },
    },
    sections: null,
    accountInfo: {
        userId: "",
        email: "",
        emailConfirmed: false,
        lastConfirmationEmailSendDate: "",
        lastEmailChangedDate: "",
        lastPasswordChangedDate: "",
        previousEmail: "",
    },
}

export const getUserAccountDetails = createAsyncThunk(
    '/identity/user/account/info',
    async (userId: string, thunkAPI) => {
        const response = await API.IdentityAPI.User.getUserAccountDetails(userId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as UserRegistrationInfoProperties;
    }
)

export const resendVerificationEmail = createAsyncThunk(
    '/identity/user/verification-email',
    async (userId: string, thunkAPI) => {
        const response = await API.IdentityAPI.User.resendVerificationEmail(userId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const assignRole = createAsyncThunk(
    '/identity/roles/assign',
    async (data: AssignRoleProperties, thunkAPI) => {
        const response = await API.IdentityAPI.User.assignRole(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const requestEmailChange = createAsyncThunk(
    '/identity/email/change',
    async (data: ChangeEmailProperties, thunkAPI) => {
        const response = await API.IdentityAPI.User.changeEmail(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

const userSlice = createSlice({
    name: 'identityUser',
    initialState: initialUserState,
    reducers: {
        clearAccountInfo(state) {
            state.accountInfo = initialUserState.accountInfo;
        }
    },
    extraReducers: (builder) => {
        //assign role 
        builder.addCase(assignRole.pending, (state) => {
            state.loading = true;
            state.error = initialUserState.error;
        })
        builder.addCase(assignRole.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(assignRole.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getUserAccountDetails
        builder.addCase(getUserAccountDetails.pending, (state) => {
            state.loadingAccountInfo = true;
            state.error = initialUserState.error;
        })
        builder.addCase(getUserAccountDetails.fulfilled, (state, action) => {
            state.loadingAccountInfo = false;
            state.accountInfo = action.payload;
        })
        builder.addCase(getUserAccountDetails.rejected, (state, action) => {
            state.loadingAccountInfo = false;
            state.error = action.payload as ErrorProperties;
        })
        //resendVerificationEmail
        builder.addCase(resendVerificationEmail.pending, (state) => {
            state.loadingAccountInfo = true;
            state.error = initialUserState.error;
        })
        builder.addCase(resendVerificationEmail.fulfilled, (state, action) => {
            state.loadingAccountInfo = false;
        })
        builder.addCase(resendVerificationEmail.rejected, (state, action) => {
            state.loadingAccountInfo = false;
            state.error = action.payload as ErrorProperties;
        })
        //reset state
        builder.addCase(resetState, () => initialUserState);
    }
})
export const { clearAccountInfo } = userSlice.actions;
export default userSlice.reducer;