import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { v4 as randomId } from "uuid";

import {
  CustomAlert,
  CustomButton,
  Picker,
} from "../../../../../../shared/uiComponents";
import {
  dateNormalizer,
  errorNormalizer,
} from "../../../../../../shared/Helpers/functions";

import {
  getInsuranceAuthorizations,
  getInsuranceAuthorizationSessionTypes,
  updateAuthorization,
} from "../../../../../../redux/State/clientSlice/insuranceAuthorizationSlice";
import { DispatchProperties } from "../../../../../../redux/store";
import {
  ActiveAuthorizationProperties,
  AddAuthorizationDetailsProperties,
  UpdateInsuranceAuthorization,
} from "../../../../../../redux/API/ClientAPIHelpers/insuranceAuthorizationProperties";
import AuthorizationDetail from "./authorizationDetail";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import AddAuthorizationContent from "./addAuthorizationContent";

export interface EditAuthorizationProperties
  extends AddAuthorizationDetailsProperties {
  id: string;
}

const EditAuthorizationContent = ({
  authorizationData,
  onClose,
}: {
  authorizationData: ActiveAuthorizationProperties;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [params] = useSearchParams();
  const { startDate, endDate, id } = authorizationData;

  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [startDateValue, setStartDateValue] = useState<Dayjs>(dayjs(startDate));
  const [endDateValue, setEndDateValue] = useState<Dayjs>(dayjs(endDate));
  const [details, setDetails] = useState<Array<EditAuthorizationProperties>>(
    authorizationData.authorizationDetails.map((x) => ({
      id: randomId(),
      authorizationSessionTypeId: x.sessionType.id,
      frequency: x.frequency.id,
      totalHours: x.totalHours,
      hoursPerWeek: x.hoursPerWeek,
    }))
  );

  const onChangeTreatmentStartDate = (value: Dayjs | null) => {
    if (!value) return;
    setStartDateValue(value);
  };

  const onChangeTreatmentEndDate = (value: Dayjs | null) => {
    if (!value) return;
    setEndDateValue(value);
  };

  const updateInsuranceAuthorization = () => {
    if (!clientId) return;
    const startDate = dateNormalizer(startDateValue);
    const endDate = dateNormalizer(endDateValue);

    const data: UpdateInsuranceAuthorization = {
      clientId,
      authorizationId: id,
      authorizationDetails: details,
      endDate,
      startDate,
    };

    dispatch(updateAuthorization(data))
      .then(unwrapResult)
      .then(() => {
        toast("Success");
        onClose();
        const page = params.get("page") || "1";
        const pageSize = params.get("pagaSize") || "8";
        dispatch(getInsuranceAuthorizations({ clientId, page, pageSize }));
      })
      .catch(errorNormalizer);
  };

  const updateAuthorizationDetailsHandler = useCallback(
    (item: EditAuthorizationProperties) => {
      const updatedData = details.map((detail) => {
        if (detail.id !== item.id) {
          return detail;
        }
        return item;
      });
      setDetails(updatedData);
    },
    [details]
  );

  const addAuthorizationDetailsHandler = useCallback(
    (item: EditAuthorizationProperties) => {
      setDetails([...details, item]);
      setOpenAdd(false);
    },
    [details]
  );

  const removeAuthorizationDetailsHandler = useCallback(
    (item: EditAuthorizationProperties) => {
      const updatedData = details.filter((x) => x.id !== item.id);
      setDetails(updatedData);
    },
    [details]
  );

  useEffect(() => {
    dispatch(
      getInsuranceAuthorizationSessionTypes(`${authorizationData.type.id}`)
    );
  }, [dispatch, authorizationData]);

  return (
    <>
      <div
        style={{ display: "flex", gap: "16px", alignItems: "center" }}
        className="marginBottom16"
      >
        <Picker.CustomDate
          label="Start Date:"
          value={startDateValue}
          onChange={onChangeTreatmentStartDate}
        />
        <Picker.CustomDate
          label="End Date:"
          value={endDateValue}
          onChange={onChangeTreatmentEndDate}
        />
      </div>
      <div
        style={{ display: "flex", gap: "16px", alignItems: "center" }}
        className="marginBottom16"
      >
        <CustomButton
          title="Save changes"
          onClick={updateInsuranceAuthorization}
        />
        {authorizationData.type.id === 2 && (
          <CustomButton title="Add New" onClick={() => setOpenAdd(true)} />
        )}
      </div>
      {details.map((x) => (
        <AuthorizationDetail
          item={x}
          startDateValue={startDateValue}
          endDateValue={endDateValue}
          authorizationTypeId={authorizationData.type.id}
          update={updateAuthorizationDetailsHandler}
          remove={removeAuthorizationDetailsHandler}
        />
      ))}
      <CustomAlert
        open={openAdd}
        onClose={() => setOpenAdd(false)}
        Content={() => (
          <AddAuthorizationContent
            endDateValue={endDateValue}
            startDateValue={startDateValue}
            update={addAuthorizationDetailsHandler}
          />
        )}
      />
    </>
  );
};

export default EditAuthorizationContent;
