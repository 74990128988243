import { useState } from "react";
import { SOAP_NOTES } from "../../pages/BCBAPanel/SOAPNote/SOAPNoteTypes/helpers";
import { CustomAlert, Text } from "../../shared/uiComponents";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useScreenWidth } from "../../shared/Helpers/hooks";

const SavedDraftsInfo = () => {
  const rawData = window.localStorage.getItem(SOAP_NOTES);
  const savedDrafts: Array<any> = !!rawData ? JSON.parse(rawData) : [];
  const [open, setOpen] = useState<boolean>(false);
  const screenWidth = useScreenWidth();

  return (
    <>
      {!!savedDrafts?.length && (
        <Text
          size="tinyBold"
          onClick={() => setOpen(true)}
          title={`You have ${savedDrafts.length} session${
            savedDrafts.length > 1 ? "s" : ""
          } pending submission.`}
          className={screenWidth < 768 ? "width150" : ""}
        />
      )}
      <CustomAlert
        open={open}
        title="Please complete drafts or submit missing notes."
        onClose={() => setOpen(false)}
        Content={({ onClose }) => (
          <>
            {savedDrafts.map((x, index) => (
              <OpenedNoteLabel
                x={x}
                index={index}
                key={index}
                onClose={onClose}
              />
            ))}
          </>
        )}
      />
    </>
  );
};

const OpenedNoteLabel = ({
  x,
  index,
  onClose,
}: {
  x: any;
  index: number;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const navigateNoteHandler = () => {
    const { id, client, sessionId } = x;
    navigate(
      `/bcba/soap-notes/info?clientId=${
        !!client?.id ? client.id : ""
      }&sessionId=${!!sessionId ? sessionId : ""}&reportId=${!!id ? id : ""}`
    );
    onClose();
  };

  return (
    <div style={{ display: "flex", gap: "16px" }} onClick={navigateNoteHandler}>
      <Text style={{ wordBreak: "unset" }} title={`${index + 1}: `} />
      {!!x.client && <Text title={x.client.fullName} />}
      <Text title={dayjs(x.startTime).format("MM/DD/YYYY hh:mm A")} />
    </div>
  );
};

export default SavedDraftsInfo;
